import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Table } from 'reactstrap';
import { OrderHistoryRow } from './index';
import LoadingIndicator from '../loadingIndicator';
import DSSQuestionsAndAnswersModal from '../modals/DSSQuestionsAndAnswersModal'

export default function OrderHistoryTable (props) {
	const { isLoaded, memberIdLabel, orders, onDetailsClick, showPatientInfo, handleBookCareOrderVisitClick, isParentCreateOrder } = props;
	const memberId = memberIdLabel ? memberIdLabel : "Patient MRN";

	const [showQuestionAndAnswersModal, setShowQuestionAndAnswersModal] = useState(false);
	const [questionsAndAnswers, setQuestionsAndAnswers] = useState(null);

	const handleShowQuestionsAndAnswersModal = (questionAndAnswers) => {
		setQuestionsAndAnswers(questionAndAnswers);
		setShowQuestionAndAnswersModal(true);
	}

	const rows = (orders && orders.map(order => {
		return <OrderHistoryRow order={order} onDetailsClick={onDetailsClick} showPatientInfo={showPatientInfo} handleBookCareOrderVisitClick={handleBookCareOrderVisitClick} isParentCreateOrder={isParentCreateOrder} handleShowQuestionsAndAnswersModal={handleShowQuestionsAndAnswersModal} />
	})) || [];

	const noResults =
		<Row>
			<Col className="text-center">No results found!</Col>
		</Row>;

	return (
		<React.Fragment>
			{ !isLoaded &&
				<LoadingIndicator />
			}
			{isLoaded &&
				<>
				{isParentCreateOrder && 
					<Table striped={rows.length > 4} responsive style={{ marginTop: "30px" }}>
						<thead className="order-form-table-header">
							<tr>
								<th>Details</th>
								<th>Order Status</th>
								<th>Entry Date</th>
								<th>Due Date</th>
								{showPatientInfo &&
									<React.Fragment>
										<th>Patient Name</th>
										<th>{memberId}</th>
									</React.Fragment>
								}
								<th>Provider & Resource</th>
								<th>Last Modified</th>
							</tr>
						</thead>
						<tbody>
							{rows && rows.length > 0 ? rows : noResults}
						</tbody>
					</Table>
				}
				{!isParentCreateOrder &&
					<Table striped={rows.length > 4} responsive style={{ marginTop: "30px" }}>
						<thead className="order-form-table-header">
							<tr>
								<th></th>
								<th>Order Status</th>
								<th>Status Reason</th>
								<th>Order Date</th>
								<th>Expiration Date</th>
								{showPatientInfo &&
									<React.Fragment>
										<th>Patient Name</th>
										<th>{memberId}</th>
									</React.Fragment>
								}
								<th>Provider & Resource</th>
								<th>Last Updated</th>
								<th>Booked Appointment</th>
								<th>Details</th>
								<th>Questions And Answers</th>
							</tr>
						</thead>
						<tbody>
							{rows && rows.length > 0 ? rows : noResults}
						</tbody>
					</Table>
				}
				</>
			}
			{showQuestionAndAnswersModal &&
				<DSSQuestionsAndAnswersModal
					isOpen={showQuestionAndAnswersModal}
					toggle={() => setShowQuestionAndAnswersModal(!showQuestionAndAnswersModal)}
					questionsAndAnswers={questionsAndAnswers}
				/>
			}
		</React.Fragment>
	);
};

OrderHistoryTable.propTypes = {
	isLoaded: PropTypes.bool,
	onDetailsClick: PropTypes.func,
	orders: PropTypes.object,
	showPatientInfo: PropTypes.bool,
	memberIdLabel: PropTypes.string,
};
