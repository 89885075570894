import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import ReadonlyHtml from "components/common/readonlyHtml";

const DSSQuestionsAndAnswersModal = ({ isOpen, toggle, questionsAndAnswers }: any) => {
    return (
        <Modal centered isOpen={isOpen} toggle={toggle} size="xl">
            <ModalHeader toggle={toggle}>Decision Support Questions And Answers</ModalHeader>
            <ModalBody>{questionsAndAnswers?.length && questionsAndAnswers.map((qna: any, index: number) => {
                return (
                    <Row key={index}>
                        <Col lg='12'>
                            <div>
                                <p className='fw-bold form-label'>
                                    <ReadonlyHtml
                                        label={''}
                                        value={qna.questionText}
                                    />
                                </p>
                                <p>{qna.answerDisplayName}</p>
                            </div>
                        </Col>
                    </Row>
                );
            })}</ModalBody>
        </Modal>
    )
}

export default DSSQuestionsAndAnswersModal