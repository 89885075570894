import {
	Col,
	Row,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button
} from 'reactstrap';
import { useState, useMemo } from 'react';
import { useSelector, useDispatch } from "react-redux";
import classnames from 'classnames';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import history from '../../lib/history';
import { PhoneInputWithLabel, EmailInputWithLabel } from '../common/standardComponents';
import { validateEmail, validatePhoneNumber } from '../../lib/validation';
import { DisclaimerHtml } from '../../nighthawk/common/miscComponents';
import { sendCareOrderToPatient } from '../../actions/patientActions'
import './careOrderPatientNotificationModal.css'

const CareOrderPatientNotificationModal = (props: any) => {

	const dispatch = useDispatch();

	const [alternatePhone, setAlternatePhone] = useState<string>("");
	const [alternateEmail, setAlternateEmail] = useState<string>("");
	const [phoneForceInvalid, setPhoneForceInvalid] = useState<boolean>(false);
	const patientDetails = useSelector((state: any) => state.activePatient.details);
	const patientNotificationConfig = useSelector((state: any) => state.config.notification);
	const [isSending, setIsSending] = useState<boolean>(false);
	const [sentFailed, setSentFailed] = useState<boolean>(false);
	const [sendingFailedText, setSendingFailedText] = useState<string>("");

	const doesPatientHaveMobileNumberOnRecord = (patientDetails?.mobilePhone !== null && patientDetails?.mobilePhone !== "");
	const doesPatientHaveEmailOnRecord = (patientDetails?.email !== null && patientDetails?.email !== "");

	const isEmailButtonVisible = useMemo(() => {
		return (patientNotificationConfig.enablePatientCareOrderEmail && (doesPatientHaveEmailOnRecord || patientNotificationConfig.allowPatientCareOrderOtherEmail));
	}, [patientNotificationConfig, doesPatientHaveEmailOnRecord]);

	const isSMSButtonVisible = useMemo(() => {
		return (patientNotificationConfig.enablePatientCareOrderSms && (doesPatientHaveMobileNumberOnRecord || patientNotificationConfig.allowPatientCareOrderOtherPhone))
	}, [doesPatientHaveMobileNumberOnRecord, patientNotificationConfig]);

	const initialNotificationType = useMemo(() => {
		return (isEmailButtonVisible ? "Email" : "SMS");
	}, [isEmailButtonVisible]);

	const [notificationType, setNotificationType] = useState<string>(initialNotificationType);

	const isPhoneRequired = useMemo(() => {
		return (notificationType === "SMS" && !doesPatientHaveMobileNumberOnRecord);
	}, [notificationType, doesPatientHaveMobileNumberOnRecord]);

	const isEmailRequired = useMemo(() => {
		return (notificationType === "Email" && !doesPatientHaveEmailOnRecord);
	}, [notificationType, doesPatientHaveEmailOnRecord]);

	const isSendDisabled = useMemo(() => {
		return (((isPhoneRequired || alternatePhone !== "") && !validatePhoneNumber(alternatePhone)) || ((isEmailRequired || alternateEmail!=="") && !validateEmail(alternateEmail)) || isSending || props.sendFailedTwice)
	}, [isPhoneRequired, isEmailRequired, alternatePhone, alternateEmail, isSending, props.sendFailedTwice]);

	const emailButtonClassName = classnames('btn',
		{ 'btn-primary': notificationType === "Email" },
		{ 'btn-light btn-light-border': notificationType !== "Email" }
	)

	const smsButtonClassName = classnames('btn',
		{ 'btn-primary': notificationType === "SMS" },
		{ 'btn-light btn-light-border': notificationType !== "SMS" }
	)

	let emailNotificationInformation = `*Care order link will be sent to ${patientDetails?.email}. ${patientNotificationConfig.allowPatientCareOrderOtherEmail ? `Optionally, you may override that Email below.`: ``}`;
	let phoneNotificationInformation = `*Care order link will be sent to ${patientDetails?.mobilePhone}. ${patientNotificationConfig.allowPatientCareOrderOtherPhone ? `Optionally, you may override that Phone below.`: ``}`;

	const shouldValidate = () => {
		return true;
	}

	const validateAlternatePhone = (e: any) => {
		setAlternatePhone(e.target.value);
		let isPhoneValid = validatePhoneNumber(e.target.value);
		setPhoneForceInvalid(!isPhoneValid);
	}

	const onAlternateEmailChange = (e: any) => {
		setAlternateEmail(e.target.value);
	}

	const sendCareOrder = async () => {
		setIsSending(true);
		let notificationDetails = {
			organizationName: "",
			phoneNumber: alternatePhone ? alternatePhone : patientDetails.mobilePhone,
			email: alternateEmail? alternateEmail : patientDetails.email,
			careOrderLinkExpirationDate: props.careOrderExpirationDate,
			language: "EN",
			careOrderVisitReferenceId: props.careOrderVisitReferenceId,
			idProductInstance: props.consumerIdProductInstance,
			userName: "",
			patientId: patientDetails.patientId,
			messageType: notificationType === "Email" ? 1 : 3
		}
		let sendResponse: any = await dispatch(sendCareOrderToPatient(notificationDetails));
		if (sendResponse.type === "PATIENT_SEND_CARE_ORDER_NOTIFICATION_FAIL") {
			if (!sentFailed) {
				setIsSending(false);
				setSentFailed(true);
				setSendingFailedText("Sending failed, please try again.");
			}
			else {
				props.setSendFailedTwice(true);
				setIsSending(false);
				setSendingFailedText("Sending failed, please contact support.");

			}
		}
		else {
			history.push(`/patient/${patientDetails.referenceId}/details`);
		}

	}

	return (
		<Modal centered isOpen={true} toggle={props.toggle} className="testModal" size="lg">
			<ModalHeader>
				<Row>
					<Col xs="auto" className="my-auto">
						<h4>Send Care Order</h4>
					</Col>
				</Row>
			</ModalHeader>
			<ModalBody>
				<div>
					{isEmailButtonVisible && isSMSButtonVisible && 
						<Row>
							<Col>
								<div className="btn-group" role="group" aria-label="Basic example">
									<button type="button" className={emailButtonClassName} onClick={() => setNotificationType("Email")}>Email</button>
									<button type="button" className={smsButtonClassName} onClick={() => setNotificationType("SMS")}>SMS</button>
								</div>
							</Col>
						</Row>
					}
					<Row>
						<Col>
							<div>
								{notificationType === "SMS" ? (
									<>
										{isPhoneRequired ? (
											<label className="text-danger">
												*Patient does not have a mobile number on their record. Alternate Mobile Number is required.
											</label>
										) : (
											<label className="text-success">
												{phoneNotificationInformation}
											</label>
										)}
									</>
								) : (
										<>
											{isEmailRequired? (
												<label className="text-danger">
													*Patient does not have an email on their record. Alternate Email address is required.
												</label>
											):(
												<label className="text-success">
													{emailNotificationInformation}
												</label>
											)}
										</>
									)
								}
							</div>
						</Col>
					</Row>
					{patientNotificationConfig.allowPatientCareOrderOtherEmail && notificationType === "Email" && isEmailButtonVisible &&
						<Row>
							<EmailInputWithLabel
								id="email"
								label="Alternate Email"
								value={alternateEmail}
								isRequired={isEmailRequired}
								disabled={false}
								shouldValidate={shouldValidate}
								onChange={onAlternateEmailChange}
							/>
						</Row>
						
					}
					{patientNotificationConfig.allowPatientCareOrderOtherPhone && notificationType === "SMS" && isSMSButtonVisible &&
						<Row>
							<PhoneInputWithLabel
								id="alternatePhone"
								label="Alternate Phone"
								forceInvalid={phoneForceInvalid}
								forcedInvalidMessage=" "
								value={alternatePhone}
								shouldValidate={shouldValidate}
								isRequired={isPhoneRequired}
								disabled={false}
								onChange={validateAlternatePhone}
								subText={isPhoneRequired ? "Required (Must be a mobile number)" : "(Must be a mobile number)"}
							/>
						</Row>
					}
					{patientNotificationConfig.patientCareOrderEmailDisclaimer && notificationType === "Email" && isEmailButtonVisible &&
						<Row style={{ "marginBottom": "15px" }}>
							<Col lg="4" style={{ "marginTop": "5px" }}>
								<DisclaimerHtml className="form-instructions" isVisible={true} text={patientNotificationConfig.patientCareOrderEmailDisclaimer} />
							</Col>
						</Row>
					}
					{patientNotificationConfig.patientCareOrderSmsDisclaimer && notificationType === "SMS" && isSMSButtonVisible &&
						<Row style={{ "marginBottom": "15px" }}>
							<Col lg="4" style={{ "marginTop": "5px" }}>
								<DisclaimerHtml className="form-instructions" isVisible={true} text={patientNotificationConfig.patientCareOrderSmsDisclaimer} />
							</Col>
						</Row>
					}
				</div>
			</ModalBody>
			<ModalFooter>
				<label className="text-danger">{sendingFailedText}</label>
				<Button color="secondary" onClick={props.toggle}>Cancel</Button>
				<Button color="primary" disabled={isSendDisabled} onClick={sendCareOrder}>
					{isSending
						? <><FontAwesomeIcon icon="spinner" spin /> Sending ...</>
						: "Send"
					}
				</Button>
			</ModalFooter>
		</Modal>
	);
}

export default CareOrderPatientNotificationModal;