
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import {
	searchCalendarsThunk,
	selectFilteredPreferredCalendarCount,
	selectIsScanningFirstAvailabilityNotPreferred,
	selectIsScanningFirstAvailabilityPreferred,
	selectIsSearchingAvailability,
	selectIsSearchingCalendars,
	selectSelectedCalendarId,
	selectShowReturnToQuestionsButton,
	flushAvailabilityAndScanResults,
	searchNextScanRangeWithReSortThunk,
} from './availabilitySlice';

import Container from './comps/container';
import Error from './comps/error';
import LoadingIndicator from '../../components/loadingIndicator';
import Pagination from './comps/pagination';
// import PatientDetails from './comps/patientDetails';
import PatientInfoTabbedCollapsable from 'features/patient/comps/patientInfoTabbedCollapsable';
import PreferredResults from './comps/preferredResults';
import Results from './comps/results';
import ResultsContainer from './comps/resultsContainer';
import ResultsHeader from './comps/resultsHeader';
import SearchForm from './comps/searchForm';
import SelectedCalendarDetails from './comps/selectedCalendarDetails';
import SelectedCalendarResults from './comps/selectedCalendarResult';
import YesNoModal from './comps/yesNoModal';
import AppInsightsLogger from './comps/appInsightsLogger';
import { decisionSupport as routeToDecisionSupport } from '../../routes';
import { Button } from 'reactstrap';

import './css/availabilitySearchResult.css';
import { scrollToPageTop } from '../../lib/misc';
import { selectIsSearchingAnything, selectScanDayCount, selectShowSaveSearchToCareOrderButton, selectShowScanNextRangeButton } from './availabilitySlice/availabilitySelectors';
import SaveSearchToCareOrder from './comps/saveSearchToCareOrder';
import SessionFlowTypes from '../../constants/sessionFlowTypes';

const AvailabilitySearchView = (props) => {

	const dispatch = useDispatch();

	const shouldAutoSearch = useSelector(state => state.careOrder?.activeCareOrderDetails?.appointments || state.config.decisionSupport.useDecisionSupport);
	const selectedCalendarId = useSelector(selectSelectedCalendarId);
	const isSearchingCalendars = useSelector(selectIsSearchingCalendars);
	const isSearchingAvailability = useSelector(selectIsSearchingAvailability);
	const isScanningFirstAvailabilityPreferred = useSelector(selectIsScanningFirstAvailabilityPreferred);
	const isScanningFirstAvailabilityNotPreferred = useSelector(selectIsScanningFirstAvailabilityNotPreferred);
	const filteredPreferredCalendarCount = useSelector(selectFilteredPreferredCalendarCount);
	const isReserving = useSelector(state => state.appointment.isLoading.reserveAppointment);
	const hasPatient = useSelector(state => state.activePatient?.details?.referenceId && state.activePatient?.details?.referenceId !== '');
	const errors = useSelector(state => state.apiErrors?.activeErrors);
	const showReturnToQuestionsButton = useSelector(selectShowReturnToQuestionsButton);
	const showSaveSearchToCareOrderButton = useSelector(selectShowSaveSearchToCareOrderButton);

	const patientConfig = useSelector((state) => state.config.patient);
	const schedulingConfig = useSelector((state) => state.config.scheduling);

	const showScanNextRangeButton = useSelector(selectShowScanNextRangeButton);
	const numberOfDaysToSearch = useSelector(selectScanDayCount);
	const isSearching = useSelector(selectIsSearchingAnything);

	const isDuplicateFlow = useSelector((state) => state.session.schedulingSessionFlowType === SessionFlowTypes.duplicateFlow);

	const handleGoBackToGuidedResponse = () => {
		dispatch(routeToDecisionSupport(true))
	};

	const showCalendarSearchFirstScanLoadingIndicator =
		isSearchingCalendars
		|| (filteredPreferredCalendarCount && isScanningFirstAvailabilityPreferred)
		|| (!filteredPreferredCalendarCount && isScanningFirstAvailabilityNotPreferred);

	const [selectedSlot, setSelectedSlot] = useState(null);

	const [isPatientHeaderCollapsed, setIsPatientHeaderCollapsed] = useState(
		!(patientConfig.notes.isVisible && schedulingConfig.autoExpandPatientHeaderForVisibleNotes),
	);

	useEffect(() => {
		dispatch(flushAvailabilityAndScanResults())
	}, [dispatch])

	useEffect(() => {
		if (shouldAutoSearch) {
			dispatch(searchCalendarsThunk())
		}
	}, [shouldAutoSearch, dispatch])

	useEffect(() => {
		if (errors?.length) {
			scrollToPageTop();
		}
	})

	return (
		<Container>
			<Error />
			<AppInsightsLogger />
			<YesNoModal
				selectedSlot={selectedSlot}
				setSelectedSlot={setSelectedSlot}
			/>
			{!selectedCalendarId &&
				<>
					{/* {hasPatient && <PatientDetails />} */}
					{hasPatient &&
						<PatientInfoTabbedCollapsable
							isOpen={!isPatientHeaderCollapsed}
							toggleIsOpen={() => setIsPatientHeaderCollapsed(x => !x)}
						/>
					}
					<SearchForm />
				</>
			}
			{(showReturnToQuestionsButton || showSaveSearchToCareOrderButton || showScanNextRangeButton) &&
				<div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", marginTop: "8px" }}>
					<div>
						{showReturnToQuestionsButton && (
							<Button color="primary" className="mt-2" onClick={handleGoBackToGuidedResponse}>
								Return to Questions
							</Button>
						)}
					</div>
					<div>
						{showSaveSearchToCareOrderButton && !isDuplicateFlow && (
							<SaveSearchToCareOrder />
						)}
						{showScanNextRangeButton && (
							<Button
								color="secondary"
								className="mt-2"
								onClick={() => dispatch(searchNextScanRangeWithReSortThunk())}
								disabled={isSearching}
								style={{ marginLeft: showSaveSearchToCareOrderButton ? "8px" : "none" }}
							>
								Search Next {numberOfDaysToSearch} Days
							</Button>
						)}
					</div>
				</div>
			}
			{showCalendarSearchFirstScanLoadingIndicator && <LoadingIndicator loadingMessage={'Searching for availability, please wait.'} />}
			{!selectedCalendarId && !showCalendarSearchFirstScanLoadingIndicator &&
				<ResultsContainer >
					<ResultsHeader />
					<div className="col">
						<PreferredResults
							selectedSlot={selectedSlot}
							setSelectedSlot={setSelectedSlot}
						/>
						{!showCalendarSearchFirstScanLoadingIndicator && isScanningFirstAvailabilityNotPreferred &&
							<LoadingIndicator loadingMessage={'Searching for availability, please wait.'} />
						}
						<Results
							selectedSlot={selectedSlot}
							setSelectedSlot={setSelectedSlot}
						/>
						<Pagination />
					</div>
				</ResultsContainer>
			}
			{selectedCalendarId &&
				<>
					{isSearchingAvailability && <LoadingIndicator loadingMessage={'Searching for availability, please wait.'} />}
					{isReserving && <LoadingIndicator loadingMessage={'Attempting to reserve timeslot.'} />}
					<SelectedCalendarDetails />
					<SelectedCalendarResults
						setSelectedSlot={setSelectedSlot}
					/>
				</>
			}
		</Container>
	);
};

export default AvailabilitySearchView;