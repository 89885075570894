import * as types from '../actions/actionTypes'

const initialState = {
	agentInstructions: {
		agentAvailabilityInstructions: '',
		agentReservationInstructions: '',
		agentPostBookingInstructions: ''
	},
	details: {},
	cancelRescheduleInfo: {
		actionReferrer: '',
		previousAppointmentDetails: {},
		previousAppointmentReferenceId: '',
		rescheduleMethod: '',
	},
	reservation: {},
	reservationDetails: {},
	requestDetails: {},
	symptomDetails: {
		reasonForVisit: '',
	},
	isLoading: {
		reserveAppointment: false,
		loadingMessage: '',
	},
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case types.TOKEN_LINK_APPOINTMENT_REFID_LOOKUP:
			return state;
		case types.APPOINTMENT_RESERVE:
			return {
				...state,
				isLoading: {
					...state.isLoading,
					reserveAppointment: true,
					loadingMessage: "Attempting to reserve timeslot.",
				}
			}
		case types.APPOINTMENT_DETAILS_SUCCESS:
			return {
				...state,
				reservation: {},
				details: action.payload.data,
			};
		case types.APPOINTMENT_BOOK_SUCCESS:
			return {
				...state,
				details: action.payload.data
			};
		case types.APPOINTMENT_RESERVE_SUCCESS:
			return {
				...state,
				reservation: action.payload.data,
				isLoading: {
					...state.isLoading,
					reserveAppointment: false,
					loadingMessage: '',
				}
			};
		case types.APPOINTMENT_RESERVE_ERROR:
			return {
				...state,
				isLoading: {
					...state.isLoading,
					reserveAppointment: false,
					loadingMessage: '',
				}
			};
		case types.APPOINTMENT_RESERVE_DETAILS_SUCCESS:
			return {
				...state,
				reservationDetails: action.payload.data
			};
		case types.APPOINTMENT_REQUEST_DETAILS_SUCCESS:
			return {
				...state,
				requestDetails: action.payload.data
			};
		case types.SET_PREVIOUS_APPOINTMENT:
			return {
				...state,
				cancelRescheduleInfo: {
					...state.cancelRescheduleInfo,
					previousAppointmentReferenceId: action.referenceId,
					rescheduleMethod: action.rescheduleMethod,
				},
			};
		case types.SET_CANCEL_RESCHEDULE_ACTION_REFERRER:
			return {
				...state,
				cancelRescheduleInfo: {
					...state.cancelRescheduleInfo,
					actionReferrer: action.actionReferrer,
				},
			};
		case types.PREVIOUS_APPOINTMENT_DETAILS_SUCCESS:
			return {
				...state,
				cancelRescheduleInfo: {
					...state.cancelRescheduleInfo,
					previousAppointmentDetails: action.payload.data,
					previousAppointmentReferenceId: action.payload.data.referenceId,
				},
			};
		case types.CLEAR_BOOKING_CONTEXT:
		case types.PATIENT_SEND_CARE_ORDER_NOTIFICATION_SUCCESS:
		case types.ON_PATIENT_DETAILS_VIEW_MOUNT:
		case types.CLEAR_PREVIOUS_APPOINTMENT:
			return {
				...state,
				reservation: {},
				reservationDetails: {},
				cancelRescheduleInfo: {
					actionReferrer: '',
					previousAppointmentDetails: {},
					previousAppointmentReferenceId: '',
					rescheduleMethod: '',
				},
			};
		case types.APPOINTMENT_RESERVATION_CANCEL_SUCCESS:
			return {
				...state,
				reservation: {},
				reservationDetails: {},
			};
		case types.CLEAR_AGENT_INSTRUCTIONS:
			return {
				...state,
				agentInstructions: {
					agentAvailabilityInstructions: '',
					agentReservationInstructions: '',
					agentPostBookingInstructions: ''
				},
			};
		case types.SET_APPOINTMENT_DETAILS_FROM_DECISION_SUPPORT_OUTPUT:
			return {
				...state,
				symptomDetails: {
					...state.symptomDetails,
					reasonForVisit: action.symptomDetails.reasonForVisit,

				},
				agentInstructions: {
					...state.agentInstructions,
					agentAvailabilityInstructions: action.agentInstructions.agentAvailabilityInstructions,
					agentReservationInstructions: action.agentInstructions.agentReservationInstructions,
					agentPostBookingInstructions: action.agentInstructions.agentPostBookingInstructions
				},
			};
		case types.SCAN_FIRST_AVAILABILITY:
		case types.SCAN_NEXT_AVAILABILITY:
		case types.SEARCH_AVAILABILITY:
		case types.SEARCH_CALENDARS:
			return {
				...state,
				isLoading: {
					...state.isLoading,
					reserveAppointment: false,
					loadingMessage: "",
				},
			};
		default:
			return state;
	}
}
