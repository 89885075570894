import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './orderHistoryRow.css'
import { convertStatusCode } from '../../lib/careOrderUtils';

export default function OrderHistoryRow(props) {
	const { order, onDetailsClick, showPatientInfo, handleBookCareOrderVisitClick, isParentCreateOrder, handleShowQuestionsAndAnswersModal } = props;
	const [isBooking, setIsBooking] = useState(false);

	const isInactive = Boolean(moment(order.expirationDate) < moment()) || order.statusCode === 'C';

	const handleBookClick = (e, careOrderInfo, handler) => {
		e.preventDefault();
		e.stopPropagation();
		setIsBooking((current) => !current);
		return handler(careOrderInfo);
	};

	return (
		<>
			{isParentCreateOrder &&
				<tr className="order-history-row-responsive">
					<td><Button color="link" onClick={(e) => onDetailsClick(e, order.id)}>Edit/View</Button></td>
					<td>{order.statusReason}</td>
					<td className="text-nowrap">{moment(order.entryDate).format('MM/DD/YYYY')}</td>
					<td className="text-nowrap">{moment(order.dueDate).format('MM/DD/YYYY')}</td>
					{showPatientInfo &&
						<React.Fragment>
							<td>{order.patientFirstName} {order.patientLastName}</td>
							<td>{order.patientMRN}</td>
						</React.Fragment>
					}
					<td>{order.careOrderVisits[0].careOrderVisitAppointments.map(app => {
						return (<React.Fragment>{app.siteName} - {app.providerFirstName} {app.providerLastName} <br /></React.Fragment>);
					})}</td>
					<td className="text-nowrap">{moment(order.lastModifiedDate).format('MM/DD/YYYY')}</td>
				</tr>
			}
			{!isParentCreateOrder &&
				<tr className="order-history-row-responsive">
					<td>
						{!isInactive ? (
							<Button
								className="bookButton"
								color="primary"
								onClick={(e) =>
									handleBookClick(
										e,
										order,
										handleBookCareOrderVisitClick,
									)
								}
							>
								{isBooking ? (
									<span>
										<FontAwesomeIcon icon="spinner" spin /> Booking...
									</span>
								) : (
									<span>Book</span>
								)}
							</Button>
						) : (
							''
						)}
					</td>
					<td>{convertStatusCode(order.statusCode)}</td>
					<td>{order.statusReason}</td>
					<td className="text-nowrap">{moment(order.orderDate).format('MM/DD/YYYY')}</td>
					<td className="text-nowrap">{order.expirationDate != null ? moment(order.expirationDate).format("MM/DD/YYYY") : "-"}</td>
					{showPatientInfo &&
						<React.Fragment>
							<td>{order.patientFirstName} {order.patientLastName}</td>
							<td>{order.patientMemberId}</td>
						</React.Fragment>
					}
					<td>
						<React.Fragment>{order.siteName} - {order.providerFirstName} {order.providerLastName} <br /></React.Fragment></td>
					<td className="text-nowrap">{moment(order.updatedAt).format('MM/DD/YYYY')}</td>
					<td className="text-nowrap">{order.providerAppointmentDateTime != null ? moment(order.providerAppointmentDateTime).format('MM/DD/YY hh:mm A') : "-"}</td>
					<td><Button color="link" onClick={(e) => onDetailsClick(e, order.id)}>Edit/View</Button></td>
					<td>
						{order.questionsAndAnswers.length ?
							<Button style={{padding: 0}} color="link" onClick={(e) => { e.preventDefault(); handleShowQuestionsAndAnswersModal(order.questionsAndAnswers) }}>Questions and Answers</Button>
							: '-'
						}
					</td>
				</tr>
			}
		</>

	);
}

OrderHistoryRow.propTypes = {
	order: PropTypes.object,
	onDetailsClick: PropTypes.func,
	showPatientInfo: PropTypes.bool,
};
