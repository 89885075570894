import * as actionTypes from './actionTypes';
import qs from 'qs';

export function retrieveCareOrderDateRanges(systemId) {
	return {
		type: actionTypes.RETRIEVE_DATE_RANGES,
		payload: {
			request: {
				method: 'get',
				url: `system/${systemId}/dateranges`,
			}
		}
	};
}

export function retrieveBookableSites(siteId) {
	return {
		type: actionTypes.RETRIEVE_BOOKABLE_SITES,
		payload: {
			request: {
				method: 'get',
				url: `site/${siteId}/practices`,
			}
		}
	};
}

export function retrieveServicesForSite(siteId, appointmentIndex) {
	return {
		type: actionTypes.RETRIEVE_PROVIDERS_FOR_SITE,
		payload: {
			request: {
				method: 'get',
				url: `site/${siteId}/services`,
			}
		},
		appointmentIndex: appointmentIndex,
	};
}

export function retrieveReferringProvidersForSite(siteId, appointmentIndex) {
	return {
		type: actionTypes.RETRIEVE_REFERRING_PROVIDERS_FOR_SITE,
		payload: {
			request: {
				method: 'get',
				url: `site/${siteId}/services`,
			}
		},
		appointmentIndex: appointmentIndex,
	};
}

export function retrieveAppointmentTypesForCalendar(siteId, serviceId, appointmentIndex) {
	return {
		type: actionTypes.RETRIEVE_APPOINTMENT_TYPES_FOR_PROVIDER,
		payload: {
			request: {
				method: 'get',
				url: `site/${siteId}/services/${serviceId}/types`,
			}
		},
		appointmentIndex: appointmentIndex,
	};
}

export function retrieveSubgroupsForSystem() {
	return {
		type: actionTypes.RETRIEVE_SUBGROUPS_FOR_SYSTEM,
		payload: {
			request: {
				method: 'get',
				url: `subgroups/list`,
			}
		}
	};
}

export function createPatientRequestedOrder(careOrder) {
	return {
		type: actionTypes.CREATE_CARE_ORDER_FROM_DECISION_SUPPORT_OUTPUTS,
		payload: {
			request: {
				method: 'post',
				url: 'patientrequestedorder',
				data: careOrder,
			}
		}
	}
}

export function createCareOrder(careOrder) {
	return {
		type: actionTypes.CREATE_CARE_ORDER,
		payload: {
			request: {
				method: 'post',
				url: 'careorder',
				data: careOrder,
			}
		}
	};
}

export function updateCareOrder(orderUpdate) {
	return {
		type: actionTypes.UPDATE_CARE_ORDER,
		payload: {
			request: {
				method: 'put',
				url: `careorder/${orderUpdate.id}/update`,
				data: orderUpdate
			}
		}
	}
}

export function removeAppointmentOptionRow(index) {
	return {
		type: actionTypes.REMOVE_APPOINTMENT_OPTION_ROW,
		index: index,
	};
}

export function searchCareOrders(searchCriteria) {
	if (!searchCriteria) {
		searchCriteria = {};
	}

	let params = qs.stringify({
		patientReferenceId: searchCriteria.patientReferenceId,
		statusCode: searchCriteria.statusCode,
		statusReasonId: searchCriteria.statusReasonId,
		siteId: searchCriteria.siteId,
		memberId: searchCriteria.memberId,
		dueDateFrom: searchCriteria.dueDateFrom,
		dueDateTo: searchCriteria.dueDateTo,
		entryDateFrom: searchCriteria.entryDateFrom,
		entryDateTo: searchCriteria.entryDateTo,
		lastModifiedDateFrom: searchCriteria.lastModifiedDateFrom,
		lastModifiedDateTo: searchCriteria.lastModifiedDateTo,
		isOverdue: searchCriteria.isOverdue,
	});

	return {
		type: actionTypes.GET_CARE_ORDERS_BY_SYSTEM,
		payload: {
			request: {
				method: 'get',
				url: 'careorders/list?' + params,
			}
		}
	};
}

export function searchCareOrderHistoryAction(searchCriteria) {
	if (!searchCriteria) {
		searchCriteria = {};
	}
	let params = qs.stringify({
		PatientReferenceId: searchCriteria.patientReferenceId,
		statusCode: searchCriteria.statusCode,
		statusReasonId: searchCriteria.statusReasonId,
		siteId: searchCriteria.siteId,
		memberId: searchCriteria.memberId,
		dueDateFrom: searchCriteria.dueDateFrom,
		dueDateTo: searchCriteria.dueDateTo,
		entryDateFrom: searchCriteria.entryDateFrom,
		entryDateTo: searchCriteria.entryDateTo,
		lastModifiedDateFrom: searchCriteria.lastModifiedDateFrom,
		lastModifiedDateTo: searchCriteria.lastModifiedDateTo,
		isOverdue: searchCriteria.isOverdue,
	});
	return {
		type: actionTypes.GET_CARE_ORDERS_BY_SYSTEM,
		payload: {
			request: {
				method: 'get',
				url: 'search/careorders?' + params,
			}
		}

	}
}

export function getCareOrderDetailsPageData(isEnabled) {
	return {
		type: actionTypes.CARE_ORDER_DETAILS_PAGE_DATA,
		payload: {
			request: {
				method: 'get',
				url: `pagedata/careOrderDetails/${isEnabled}`,
			}
		}
	};
}

export function getCareOrderHistory(id) {
	return {
		type: actionTypes.GET_CARE_ORDER_HISTORY,
		payload: {
			request: {
				method: 'get',
				url: `careorder/${id}/history`,
			}
		}
	};
}

export function getSearchCareOrdersPageData() {
	return {
		type: actionTypes.SEARCH_CARE_ORDERS_PAGE_DATA,
		payload: {
			request: {
				method: 'get',
				url: `pagedata/searchCareOrders`,
			}
		}
	};
}
