/**
 * @format
 */
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { deepCopy } from 'nighthawk/utility/miscUtils';
import { faChevronDown, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchDecisionSupportSessionOutput } from "actions/sessionActions";
import moment from 'moment';
import { forwardRef, useState, useMemo } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Collapse, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import {
	displayFullName,
	displayProviderNameWithProfessionalDesignation,
	valueDisplay,
	valueDisplayBottomMargin,
} from '../../lib/standardLibrary';
import { scrollToPageTop } from '../../lib/misc';
import { PhoneNumber } from '../common/phoneNumber';
import { BookAnotherAppointmentModal, PreviousAppointmentDetailsModal } from '../common/standardComponents';
import ReadonlyHtml from '../common/readonlyHtml';
import './appointmentDetails.css';

const SAME_PROVIDER = 'sameProvider';
const PRACTICE_SCHEDULED = 'Practice Scheduled';

export const AppointmentDetails = forwardRef((props, ref) => {
	const {
		activeCareOrder,
		agentInstructions,
		appointmentManagementConfig,
		bookAnotherAppointment,
		className,
		decisionSupportResponsesToggle,
		details,
		enableDecisionSupportHistory,
		handleCancelClick,
		handleEditClick,
		handleNewSearchClick,
		handleRescheduleClick,
		history,
		isAppointmentCancelled = false,
		isDecisionSupportResponsesCollapsed,
		isGroupNumberActive,
		isMemberIdActive,
		isRescheduled,
		memberIdFieldLabel,
		onViewPreviousClick,
		pastModal,
		providerFieldConfig,
		previousAppointmentModal,
		printOrder,
		rescheduleMethod,
		rescheduledAppointmentDetails,
		schedulingConfig,
		sessionQuestionsAndAnswersInfo,
		showInsuranceOnAppointmentDetails,
		showProviderResponseApptDetails,
		showReasonForVisitApptDetails,
		togglePastModal,
		togglePreviousAppointmentDetailsModal,
		verifyFuture,
	} = props;
	
	const patientDetailsClick = () => {
		scrollToPageTop();
		history.push(`/patient/${details.patientReferenceId}/details`);
	};

	const [showBookAnotherAppointmentModal, setShowBookAnotherOptionsModal] = useState(false);
	const [isLoadingDssData, setIsLoadingDssData] = useState(false);
	const [isHandlingBookAnother, setIsHandlingBookAnother] = useState(false);

	const dispatch = useDispatch();
	const location = useLocation();

	const decisionSupportSessionSubpoints = useSelector(state => state.session.decisionSupportSubpoints);

	const handleBookAnotherAppointmentClick = () => {
		setShowBookAnotherOptionsModal(true);
		setIsLoadingDssData(true);

		dispatch(fetchDecisionSupportSessionOutput(details.decisionSupportSessionId)).then(() => {
			setIsLoadingDssData(false);
		});
	}

	const providerConfig = {
		fax: providerFieldConfig.fax,
		phone: providerFieldConfig.phone,
	};

	let providerFullName = displayFullName(details.providerFirstName, details.providerLastName);
	let providerFullNameProfessionalDesignation = displayProviderNameWithProfessionalDesignation(
		providerFullName,
		details.providerProfessionalDesignation,
	);

	let previousProviderFullName = isRescheduled
		? displayFullName(rescheduledAppointmentDetails.providerFirstName, rescheduledAppointmentDetails.providerLastName)
		: '';
	let previousProviderFullNameProfessionalDesignation = isRescheduled
		? displayProviderNameWithProfessionalDesignation(
			previousProviderFullName,
			rescheduledAppointmentDetails.providerProfessionalDesignation,
		)
		: '';

	let appointmentCancelerFullName = displayFullName(details.appointmentCancelerFirstName, details.appointmentCancelerLastName)

	let showEditAppointmentButton = appointmentManagementConfig.enableEditProviderResponse;

	let rescheduledWithSameProvider = Boolean(rescheduleMethod === SAME_PROVIDER);
	let headerText = isRescheduled ? 'Rescheduled Appointment Details' : 'Appointment Details';
	let appointmentSubheaderText = isRescheduled ? 'New Appointment Information' : 'Appointment Information';

	const isPsaAppointment = Boolean(details.appointmentTypeName === PRACTICE_SCHEDULED);
	const allowReschedule =
		!isPsaAppointment && schedulingConfig.allowMHDReschedules
			? true
			: isPsaAppointment && schedulingConfig.allowPSAReschedules
				? true
				: false;
	const allowCancel =
		!isPsaAppointment && schedulingConfig.allowMHDCancellations
			? true
			: isPsaAppointment && schedulingConfig.allowPSACancellations
				? true
				: false;

	let showArrivalTime = Boolean(
		details.expectedArrivalTime && details.expectedArrivalTime !== details.providerAppointmentDateTime,
	);
	let showPreviousArrivalTime =
		isRescheduled &&
		Boolean(
			rescheduledAppointmentDetails.expectedArrivalTime &&
			rescheduledAppointmentDetails.expectedArrivalTime !==
			rescheduledAppointmentDetails.providerAppointmentDateTime,
		);

	let showMappedAppointmentType = Boolean(
		details.mappedAppointmentTypeName && details.mappedAppointmentTypeName !== details.appointmentTypeName,
	);

	let isFuture = verifyFuture();
	let careOrderContext =
		activeCareOrder && activeCareOrder.activeCareOrderDetails.careOrderId
			? activeCareOrder.activeCareOrderDetails.appointments[0]
			: null;

	let chevron = <FontAwesomeIcon style={{ marginLeft: '10px' }} icon={faChevronDown} size='sm' />;

	if (isDecisionSupportResponsesCollapsed) {
		chevron = <FontAwesomeIcon style={{ marginLeft: '10px' }} icon={faChevronRight} size='sm' />;
	} else {
		chevron = <FontAwesomeIcon style={{ marginLeft: '10px' }} icon={faChevronDown} size='sm' />;
	}

	const disableBookAnotherWithSameDSS = useMemo(() => {
		let retVal = true;
		if (details && !isAppointmentCancelled) {
			retVal = Boolean(!(schedulingConfig.enableBookAnotherWithSameDecisionSupport || schedulingConfig.enableBookAnotherWithSameDecisionSupportAndProvider)
				|| !(details.decisionSupportTypeCode && details.decisionSupportTypeCode === 'GR'));

			if (!retVal && details.flowEndTime) {
				var startTime = moment(details.flowEndTime);
				var endTime = moment.utc();
				var days = Math.abs(startTime.diff(endTime, 'days', true));
				return retVal = days > schedulingConfig.bookAnotherWithSameDecisionSupportMaxAgeInDays
			}
			else {
				return retVal = true;
			}
		}
		return true;
	}, [details, schedulingConfig, isAppointmentCancelled])

	return (
		<div ref={ref}>
			<Modal isOpen={pastModal} toggle={togglePastModal} className={className}>
				<ModalBody>Cannot cancel: The appointment's start time has passed.</ModalBody>
				<ModalFooter>
					<Button color='primary' onClick={togglePastModal}>
						OK
					</Button>
				</ModalFooter>
			</Modal>
			<PreviousAppointmentDetailsModal
				onCancel={togglePreviousAppointmentDetailsModal}
				previousAppointmentModal={previousAppointmentModal}
				details={rescheduledAppointmentDetails}
				showProviderResponseApptDetails={showProviderResponseApptDetails}
				showReasonForVisitApptDetails={showReasonForVisitApptDetails}
				providerFullNameProfessionalDesignation={previousProviderFullNameProfessionalDesignation}
				providerConfig={providerConfig}
				showInsuranceOnAppointmentDetails={showInsuranceOnAppointmentDetails}
			/>
			<Button className='backToPatientBtn' onClick={patientDetailsClick}>
				<FontAwesomeIcon icon={faChevronLeft} size='sm' />
				Back to Patient
			</Button>
			<h4 style={{ marginBottom: '10px' }}>{headerText}</h4>
			{!details ? (
				<div>
					<Row>
						<Col>
							<p className='fw-bold form-label'>No appointment found!</p>
						</Col>
					</Row>
				</div>
			) : (
				<div className='col-lg-12 col-md-12 col-sm-12' style={{ paddingLeft: '0px', paddingTop: '20px' }}>
					<Row style={{ marginBottom: '20px' }}>
						<Col lg='3'>
							<div
								style={{
									background: '#FAFAFA',
									border: 'solid 1px #E0E0E0',
									padding: '10px',
									height: '100%',
									borderRadius: '5px',
								}}
							>
								<h5 className='fw-bold' style={{ marginBottom: '10px' }}>
									Patient Information
								</h5>
								<div>
									<p className='fw-bold form-label'>NAME</p>
									<p style={valueDisplay}>
										{details.patientFirstName ? `${details.patientFirstName} ${details.patientLastName}` : '-'}
									</p>
								</div>
								{details.patientMemberId && isMemberIdActive === true ? (
									<div>
										<p className='fw-bold form-label'>
											{!memberIdFieldLabel ? '' : memberIdFieldLabel.toUpperCase()}
										</p>
										<p>{details.patientMemberId}</p>
									</div>
								) : (
									<div></div>
								)}
								{details.patientGroupNumber && isGroupNumberActive === true ? (
									<div>
										<p className='fw-bold form-label'>GROUP NUMBER</p>
										<p>{details.patientGroupNumber}</p>
									</div>
								) : (
									<div></div>
								)}
								<div>
									<p className='fw-bold form-label'>DATE OF BIRTH</p>
									<p>{details.patientDateOfBirth ? moment(details.patientDateOfBirth).format('MM/DD/YYYY') : '-'}</p>
								</div>
								<div>
									<p className='fw-bold form-label'>HOME</p>
									{details.patientHomePhone ? <PhoneNumber value={details.patientHomePhone} /> : <p>{'-'}</p>}
								</div>
								<div>
									<p className='fw-bold form-label'>MOBILE</p>
									{details.patientMobilePhone ? <PhoneNumber value={details.patientMobilePhone} /> : <p>{'-'}</p>}
								</div>
								<div>
									<p className='fw-bold form-label'>ADDRESS</p>
									{details.patientAddressLine1 ? (
										<div>
											<p style={{ margin: '0px' }}>{details.patientAddressLine1}</p>
											{details.patientAddressLine2 ? (
												<p style={{ margin: '0px' }}>{details.patientAddressLine2}</p>
											) : (
												''
											)}
											<p>
												{details.patientCity}
												{', '}
												{details.patientState} {details.patientZip}
											</p>
										</div>
									) : (
										'-'
									)}
								</div>
							</div>
						</Col>
						<Col lg='9'>
							<div
								style={{
									background: '#FAFAFA',
									border: 'solid 1px #E0E0E0',
									padding: '10px',
									height: '100%',
									borderRadius: '5px',
								}}
							>
								<h5 className='fw-bold' style={{ marginBottom: '10px' }}>
									{appointmentSubheaderText}
								</h5>
								{isRescheduled && !rescheduledWithSameProvider && (
									<a id='previousAppointmentLink' href='#Previous' onClick={(e) => onViewPreviousClick(e)}>
										(Previous Appointment Information)
									</a>
								)}
								<Row>
									<Col lg='4'>
										<div>
											<p className='fw-bold form-label'>WHEN</p>
											{isRescheduled && rescheduledWithSameProvider && (
												<>
													<p className='ml-4 fw-bold font-italic form-label text-success'>NEW</p>
													<p className='ml-4 text-success' style={valueDisplay}>
														{details.providerAppointmentDateTime
															? moment(details.providerAppointmentDateTime).format('dddd, MM/DD/YYYY')
															: '-'}
													</p>
													<p className='ml-4 text-success' style={valueDisplay}>
														{details.patientAppointmentDateTime
															? moment(details.patientAppointmentDateTime).format('hh:mm A') +
															' ' +
															(details.patientAppointmentTimeZone ? details.patientAppointmentTimeZone : '')
															: '-'}
														{' (patient time)'}
													</p>
													<p className='ml-4 text-success' style={valueDisplay}>
														{details.providerAppointmentDateTime
															? moment(details.providerAppointmentDateTime).format('hh:mm A') +
															' ' +
															(details.providerAppointmentTimeZone ? details.providerAppointmentTimeZone : '')
															: '-'}
														{' (provider time)'}
													</p>
													{showArrivalTime && (
														<p className='ml-4 text-success' style={valueDisplayBottomMargin}>
															{details.expectedArrivalTime
																? moment(details.expectedArrivalTime).format('hh:mm A') +
																' ' +
																(details.providerAppointmentTimeZone ? details.providerAppointmentTimeZone : '')
																: '-'}
															{' (arrival time)'}
														</p>
													)}
													<p className='ml-4 fw-bold form-label'>Previous</p>
													<p className='ml-4' style={valueDisplay}>
														{rescheduledAppointmentDetails.providerAppointmentDateTime
															? moment(rescheduledAppointmentDetails.providerAppointmentDateTime).format(
																'dddd, MM/DD/YYYY',
															)
															: '-'}
													</p>
													<p className='ml-4' style={valueDisplay}>
														{rescheduledAppointmentDetails.patientAppointmentDateTime
															? moment(rescheduledAppointmentDetails.patientAppointmentDateTime).format('hh:mm A') +
															' ' +
															(rescheduledAppointmentDetails.patientAppointmentTimeZone
																? rescheduledAppointmentDetails.patientAppointmentTimeZone
																: '')
															: '-'}
														{' (patient time)'}
													</p>
													<p className='ml-4' style={valueDisplay}>
														{rescheduledAppointmentDetails.providerAppointmentDateTime
															? moment(rescheduledAppointmentDetails.providerAppointmentDateTime).format('hh:mm A') +
															' ' +
															(rescheduledAppointmentDetails.providerAppointmentTimeZone
																? rescheduledAppointmentDetails.providerAppointmentTimeZone
																: '')
															: '-'}
														{' (provider time)'}
													</p>
													{showPreviousArrivalTime && (
														<p className='ml-4' style={valueDisplayBottomMargin}>
															{rescheduledAppointmentDetails.expectedArrivalTime
																? moment(rescheduledAppointmentDetails.expectedArrivalTime).format('hh:mm A') +
																' ' +
																(rescheduledAppointmentDetails.providerAppointmentTimeZone
																	? rescheduledAppointmentDetails.providerAppointmentTimeZone
																	: '')
																: '-'}
															{' (arrival time)'}
														</p>
													)}
												</>
											)}
											{(!isRescheduled || !rescheduledWithSameProvider) && (
												<>
													<p style={valueDisplay}>
														{details.providerAppointmentDateTime
															? moment(details.providerAppointmentDateTime).format('dddd, MM/DD/YYYY')
															: '-'}
													</p>
													<p style={valueDisplay}>
														{details.patientAppointmentDateTime
															? moment(details.patientAppointmentDateTime).format('hh:mm A') +
															' ' +
															(details.patientAppointmentTimeZone ? details.patientAppointmentTimeZone : '')
															: '-'}
														{' (patient time)'}
													</p>
													<p style={valueDisplay}>
														{details.providerAppointmentDateTime
															? moment(details.providerAppointmentDateTime).format('hh:mm A') +
															' ' +
															(details.providerAppointmentTimeZone ? details.providerAppointmentTimeZone : '')
															: '-'}
														{' (provider time)'}
													</p>
													{showArrivalTime && (
														<p style={valueDisplay}>
															{details.expectedArrivalTime
																? moment(details.expectedArrivalTime).format('hh:mm A') +
																' ' +
																(details.providerAppointmentTimeZone ? details.providerAppointmentTimeZone : '')
																: '-'}
															{' (arrival time)'}
														</p>
													)}
												</>
											)}
										</div>
									</Col>
									<Col lg='8'>
										<div>
											<p className='fw-bold form-label'>WHERE</p>
											<p style={valueDisplay}>
												{details.providerFirstName || details.providerLastName
													? providerFullNameProfessionalDesignation
													: '-'}
											</p>
											<p style={valueDisplay}>{details.providerSpecialty ? details.providerSpecialty : '-'}</p>
											<p style={valueDisplay}>
												{details.providerSiteName ? details.providerSiteName : '-'}
												{details.providerSiteName && details.providerSystemName ? ', ' : ''}
												{details.providerSystemName ? details.providerSystemName : ''}
											</p>
											{!details.suppressPhoneNumber && providerConfig.phone && providerConfig.phone.isVisible && (
												<PhoneNumber
													label={providerConfig.phone.fieldLabel ? providerConfig.phone.fieldLabel : '(O)'}
													value={details.providerPhoneNumber?.trim()}
												/>
											)}
											{providerConfig.fax && providerConfig.fax.isVisible && (
												<PhoneNumber
													label={providerConfig.fax.fieldLabel ? providerConfig.fax.fieldLabel : '(F)'}
													value={details.providerFaxNumber?.trim()}
												/>
											)}
											{!details.suppressAddress && details.providerAddressLine1 ? (
												<div>
													<p style={valueDisplay}>{details.providerAddressLine1}</p>
													{details.providerAddressLine2 ? (
														<p style={valueDisplay}>{details.providerAddressLine2}</p>
													) : (
														''
													)}
													<p>
														{details.providerCity}
														{', '}
														{details.providerState} {details.providerZip}
													</p>
												</div>
											) : (
												<div></div>
											)}
										</div>
									</Col>
								</Row>
								<Row>
									<Col lg='4'>
										<div>
											<p className='fw-bold form-label'>TYPE</p>
											<p style={valueDisplayBottomMargin}>
												{details.appointmentTypeName ? details.appointmentTypeName : '-'}
											</p>
										</div>
										{showMappedAppointmentType && (
											<div className='mt-4'>
												<p className='fw-bold form-label'>MAPPED APPOINTMENT TYPE</p>
												<p style={valueDisplayBottomMargin}>{details.mappedAppointmentTypeName}</p>
											</div>
										)}
									</Col>
									<Col lg='8'>
										{showInsuranceOnAppointmentDetails ? (
											<div>
												<p className='fw-bold form-label'>INSURANCE</p>
												{details.payorType ? (
													<div>
														<p style={valueDisplay}>{details.payorType}</p>
													</div>
												) : (
													<div></div>
												)}
												{details.insuranceCarrierName ? (
													<div>
														<p style={valueDisplay}>{details.insuranceCarrierName}</p>
													</div>
												) : (
													<div></div>
												)}
											</div>
										) : (
											<div>
												<p>&nbsp;</p>
												<p style={valueDisplayBottomMargin}>&nbsp;</p>
											</div>
										)}
									</Col>
								</Row>
								<Row>
									<Col lg='4'>
										{showReasonForVisitApptDetails ? (
											<div>
												<p className='fw-bold form-label'>REASON FOR VISIT</p>
												<p style={valueDisplayBottomMargin}>
													{details.reasonForVisit ? details.reasonForVisit : '-'}
												</p>
											</div>
										) : (
											<div>
												<p>&nbsp;</p>
												<p style={valueDisplayBottomMargin}>&nbsp;</p>
											</div>
										)}
									</Col>
									<Col lg='8'>
										{showProviderResponseApptDetails ? (
											<div>
												<p className='fw-bold form-label'>PROVIDER RESPONSE</p>
												<p style={valueDisplayBottomMargin}>
													{details.providerResponse ? details.providerResponse : '-'}
												</p>
											</div>
										) : (
											<div>
												<p>&nbsp;</p>
												<p style={valueDisplayBottomMargin}>&nbsp;</p>
											</div>
										)}
									</Col>
								</Row>
								<Row className='no-print'>
									<Col lg='4'>
										<div>
											<p className='fw-bold form-label'>PATIENT ATTENDANCE</p>
											<p style={valueDisplayBottomMargin}>
												{details.attendance ? details.attendance : 'Not Reported'}
											</p>
										</div>
									</Col>
									{isAppointmentCancelled &&
										<Col lg='8' className='text-center'>
											<div>
												<p className='bg-warning-subtle' style={{ paddingRight: '10px', marginBottom: '0px', lineHeight: '3' }}>
													Appointment cancelled
													{details.appointmentCancelerFirstName || details.appointmentCancelerLastName
														? ' by ' + appointmentCancelerFullName
														: ''}
													{details.appointmentCancelledTime
														? ' on ' + moment(details.appointmentCancelledTime).format('MM/DD/YYYY hh:mm A ') +
														details.scheduledDateTimeZone
														: ''}
												</p>
											</div>
										</Col>
									}
									{!isPsaAppointment && isFuture && details.hasRequestedWaitlist && !isAppointmentCancelled &&
										<Col lg='8' className='text-center'>
											<div>
												<p className='bg-warning-subtle' style={{ paddingRight: '10px', marginBottom: '0px', lineHeight: '3' }}>
													Requested earlier appointment
												</p>
											</div>
										</Col>
									}
								</Row>
							</div>
						</Col>
					</Row>
					{careOrderContext ||
						details.infoForConsumerImageFile ||
						details.informationForConsumer ||
						details.informationForPatient ||
						agentInstructions ? (
						<Row style={{ marginBottom: '20px' }}>
							<Col lg='12'>
								<div style={{ background: '#FAFAFA', border: 'solid 1px #E0E0E0', padding: '10px' }}>
									<h5 className='fw-bold' style={{ marginBottom: '10px' }}>
										Additional Information
									</h5>
									{careOrderContext && (
										<div>
											<Row>
												<Col lg='12'>
													<div style={{ padding: '10px' }}>
														<p className='fw-bold form-label'>CARE ORDER DETAILS</p>
														<Row>
															<Col lg='3'>
																<div>
																	<p className='fw-bold form-label'>External Referral Order Id</p>
																	<p style={valueDisplay}>
																		{careOrderContext.externalReferralOrderId
																			? careOrderContext.externalReferralOrderId
																			: '-'}
																	</p>
																</div>
															</Col>
															<Col lg='4'>
																<div>
																	<p className='fw-bold form-label'>Resource</p>
																	<p>
																		{careOrderContext.providerFirstName} {careOrderContext.providerLastName} |{' '}
																		{careOrderContext.siteName}
																	</p>
																</div>
															</Col>
														</Row>
													</div>
												</Col>
											</Row>
										</div>
									)}
									{details.infoForConsumerImageFile ? (
										<div>
											<p>
												<img src={details.infoForConsumerImageFile} alt='providerImage' />
											</p>
										</div>
									) : (
										<div></div>
									)}
									{agentInstructions ? (
										<div>
											<p className='fw-bold form-label'>AGENT INSTRUCTIONS</p>
											<p>{agentInstructions}</p>
										</div>
									) : (
										<div></div>
									)}
									{details.informationForConsumer ? (
										<div>
											<p className='fw-bold form-label'>INFORMATION FROM PROVIDER</p>
											<p dangerouslySetInnerHTML={{ __html: details.informationForConsumer }}></p>
										</div>
									) : (
										<div></div>
									)}
									{details.informationForPatient ? (
										<div>
											<p className='fw-bold form-label'>PRE-VISIT INFORMATION FOR PATIENT</p>
											<p dangerouslySetInnerHTML={{ __html: details.informationForPatient }}></p>
										</div>
									) : (
										<div></div>
									)}
								</div>
							</Col>
						</Row>
					) : (
						<div></div>
					)}
					{details.emailAttachments && details.emailAttachments.length > 0 ? (
						<Row style={{ marginBottom: '20px' }}>
							<Col lg='12'>
								<div style={{ background: '#FAFAFA', border: 'solid 1px #E0E0E0', padding: '10px' }}>
									<h5 className='fw-bold' style={{ marginBottom: '10px' }}>
										Attachments
									</h5>
									<ul className='provider-languages'>
										{details.emailAttachments.map((attachment, i) => {
											let fileName = attachment.substring(attachment.indexOf('/') + 1);
											return <li key={uuidv4()}>{fileName}</li>;
										})}
									</ul>
								</div>
							</Col>
						</Row>
					) : (
						<div></div>
					)}
					<div className='no-print' style={{ marginBottom: '20px', marginTop: '10px' }}>
						<Row>
							<Col lg='12'>
								<div style={{ padding: '10px' }}>
									<h5 className='fw-bold' style={{ marginBottom: '10px' }}>
										Referral Information
									</h5>
									<Row>
										<Col lg='3'>
											<div>
												<p className='fw-bold form-label'>BOOKED BY</p>
												<p style={valueDisplay}>{details.scheduledBy ? details.scheduledBy : '-'}</p>
												{details.scheduledByEmail ? <p>{details.scheduledByEmail}</p> : <div></div>}
											</div>
										</Col>
										<Col lg='4'>
											<div>
												<p className='fw-bold form-label'>TIMESTAMP</p>
												<p>
													{details.scheduledDateTime
														? moment(details.scheduledDateTime).format('MM/DD/YYYY hh:mm A ') +
														details.scheduledDateTimeZone
														: '-'}
												</p>
											</div>
										</Col>
									</Row>
								</div>
							</Col>
						</Row>
						{!isAppointmentCancelled &&
							<Row>
								<Col lg='12'>
									<div style={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
										<Button color='primary' onClick={handleNewSearchClick} style={{ marginRight: '8px' }}>
											Start a new search
										</Button>
										{!details.isBookingDisabled && (
											<>
												{(schedulingConfig.enableBookAnotherWithSameDecisionSupport || schedulingConfig.enableBookAnotherWithSameDecisionSupportAndProvider) ? (
													<Button color='primary' onClick={handleBookAnotherAppointmentClick} disabled={disableBookAnotherWithSameDSS} style={{ marginRight: '8px' }}>
														Book another appointment
													</Button>
												) : (
													<Button color='primary' onClick={bookAnotherAppointment} style={{ marginRight: '8px' }}>
														Book another appointment
													</Button>
												)}
											</>
										)}
										{showEditAppointmentButton && (
											<Button color='primary' onClick={handleEditClick} style={{ marginRight: '8px' }}>
												Edit appointment notes
											</Button>
										)}
										{isFuture ? (
											<>
												{allowReschedule && !details.isBookingDisabled && (
													<Button color='primary' onClick={handleRescheduleClick} style={{ marginRight: '8px' }}>
														Reschedule appointment
													</Button>
												)}
												{allowCancel && <Button onClick={handleCancelClick}>Cancel appointment</Button>}
											</>
										) : (
											''
										)}
										{printOrder()}
									</div>
								</Col>
							</Row>
						}
						{enableDecisionSupportHistory &&
							sessionQuestionsAndAnswersInfo?.questionAndAnswers?.length &&
							<Row>
								<Col lg='12'>
									<div className='decision-support-responses' style={{ margin: '20px 0px' }}>
										<Card>
											<CardHeader>
												<h4 onClick={decisionSupportResponsesToggle} className='hand'>
													{`Decision Support Responses`} {chevron}
												</h4>
											</CardHeader>
											<Collapse isOpen={!isDecisionSupportResponsesCollapsed}>
												<CardBody>
													{/* map from decision support responses here */}
													{sessionQuestionsAndAnswersInfo?.questionAndAnswers?.length && sessionQuestionsAndAnswersInfo?.questionAndAnswers.map((qna, index) => {
														return (
															<Row key={index}>
																<Col lg='12'>
																	<div>
																		<p className='fw-bold form-label'>
																			<ReadonlyHtml
																				label={''}
																				value={qna.questionText}
																			/>
																		</p>
																		<p>{qna.answerDisplayName}</p>
																	</div>
																</Col>
															</Row>
														);
													})}
												</CardBody>
											</Collapse>
										</Card>
									</div>
								</Col>
							</Row>
						}
					</div>
				</div>
			)}
			{showBookAnotherAppointmentModal &&
				<BookAnotherAppointmentModal
					config={schedulingConfig}
					decisionSupportSessionSubpoints={decisionSupportSessionSubpoints}
					deepCopy={deepCopy}
					dispatch={dispatch}
					isLoading={isLoadingDssData || isHandlingBookAnother}
					isOpen={true}
					location={location}
					serviceNpi={details.serviceNpi}
					setShowBookAnotherOptionsModal={setShowBookAnotherOptionsModal}
					setIsHandlingBookAnother={setIsHandlingBookAnother}
				/>
			}
		</div>
	);
});

export default AppointmentDetails;
