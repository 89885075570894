import * as fieldBehavior from '../lib/fieldBehavior';
import * as appointmentFieldsConst from '../constants/appointmentFields';
import * as availabilitySearchFieldsConst from '../constants/availabilitySearchFields';
import * as careOrderFieldsConst from '../constants/careOrderFields';
import * as careOrderPanelFieldsConst from '../constants/careOrderPanelFields';
import * as openWorkflowPanelFieldsConst from '../constants/openWorkflowPanelFields';
import * as patientFieldsConst from '../constants/patientFields';
import * as providerFieldsConst from '../constants/providerFields';
import * as types from '../actions/actionTypes';

const defaultOption = { id: 0, name: 'Select an option' };
const initialState = {
	availabilitySearch: {
		chunkSize: 0,
		defaultPageSize: 0,
		defaultSearchRadius: 0,
		searchDays: 0,
		availabilitySearchWindow: 0,
		availabilitySortOrder: {
			availabilitySortOrderId: null,
			displayName: '',
			name: '',
		},
		appointmentDateBehavior: null,
		enableAppointmentTypeTooltips: true,
		suppressSaturday: false,
		suppressSunday: false,
		payorTypeBehavior: null,
		insuranceStateBehavior: null,
		insuranceCarrierBehavior: null,
		serviceCategoryBehavior: null,
		skipToFirstAvailability: true, //TODO add to config
		specialtiesBehavior: null,
		zipCodeBehavior: null,
		searchRadiusBehavior: null,
		serviceNameBehavior: null,
		providerGenderBehavior: null,
		languagePreferenceBehavior: null,
		allowReturnToDecisionSupport: false,
		searchPreferredProvidersFirst: false,
		useNewAvailabilitySearchViewByDefault: false
	},
	availabilitySearchSupportData: {},
	decisionSupport: {
		useDecisionSupport: false,
		useGuidedResponse: false,
		flowId: null,
		apWorkspaceId: null,
		apAlgorithmId: null,
		apServerUrl: null,
		apUseJwt: false,
		enableDecisionSupportHistory: false
	},
	externalAuth: {
		enableSingleSignOn: false,
		id: 0,
		singleSignOnUrl: '',
		isAutoLoginEnabled: false
	},
	notification: {
		emailConfirmationEnabled: false,
		emailCancellationEnabled: false,
		emailReminderEnabled: false,
		textReminderEnabled: false,
		voiceReminderEnabled: false,
		minEmailReminderLeadTime: 0,
		minTextReminderLeadTime: 0,
		minVoiceReminderLeadTime: 0,
		patientConfirmationEmailDisclaimer: '',
		patientCancellationEmailDisclaimer: '',
		patientReminderEmailDisclaimer: '',
		patientReminderVoiceDisclaimer: '',
		patientReminderSmsDisclaimer: '',
		showPatientConfirmationEmailOptIn: false,
		showPatientCancellationEmailOptIn: false,
	},
	patient: {
		allowUnknownGender: false,
		unknownGenderOptionText: 'Unspecified',
		isViewEnabled: true,
		isEditEnabled: true,
		isAddEnabled: true,
		enablePatientSearchByDemographics: false,
		enablePatientSearchByIdentity: false,
		enablePatientSearchByMemberId: false,
		enablePatientSearchByPhone: false,
		patientIdentityFieldLabelOverride: '',
		excludeWithoutMemberIdFromPatientSearch: false,
		usePatientProxy: false,
		firstNameRequired: true,
		lastNameRequired: true,
		dateOfBirthRequired: true,
		isPatientHistoryEnabled: true,
		showPatientHistoryInPatientInfoPanel: false,
		isPatientCareOrderHistoryEnabled: false,
		includeInactiveCareOrders: false,
		isDecisionSupportWorkflowHistoryEnabled: false,
		allowExternalPatientDetailsUrl: false,
		patientHistory: {
			isViewAppointmentDetailsEnabled: true,
			isCancelAppointmentEnabled: true,
			isIncludeCancelledAppointmentsEnabled: true,
			includeCancelledAppointmentsDefault: false,
		},
		memberId: {
			fieldLabel: 'Member Id',
			isActive: false,
			isRequired: false,
			maxLength: 255,
		},
		groupNumber: {
			fieldLabel: 'Group Number',
			isActive: false,
			isRequired: false,
			maxLength: 255,
		},
		guardian: {
			fieldLabel: 'Guardian Name',
			isActive: true,
			maxLength: 255,
			adultAge: 18,
		},
	},
	patientSearch: {
		firstName: {},
		lastName: {},
		dateOfBirth: {}, //to do: set intital state
	},
	provider: {
		fax: {},
		phone: {},
	},
	appointmentManagement: {
		enableEditReasonForVisit: false,
		enableEditProviderResponse: false,
		allowExternalAppointmentDetailsUrl: false,
	},
	orderManagement: {
		usesSystemDateRange: false,
		maxAppointmentsPerVisit: 0,
		maxVisitsPerOrder: 0,
		usesExternalOrders: false,
		closeCareOrderEnabled: false,
		patientRequestedOrderEnabled: false,
		idProductInstanceConsumerCareOrder: null,
		patientRequestedOrderExpirationLeadDays: 0,
	},
	orderScheduling: {
		enableOrderDetailsPanel: false,
		autoExpandOrderDetailsPanel: false,
		searchType: "Internal"
	},
	scheduling: {
		languageId: 0,
		maxAttachments: 0,
		reasonForVisit: null,
		referringProvider: {
			useReferringProviders: false,
			allowInternalReferringProviders: false,
			allowExternalReferringProviders: false,
			allowOrganizationReferringProviders: false,
			enableInternalReferringProviderServerTypeAhead: false,
			internalReferringProviderServerTypeAheadMinimumCharacters: 0,
		},
		showProviderResponseApptDetails: false,
		showReasonForVisitApptDetails: false,
		showProviderResponsePatientHistory: false,
		showReasonForVisitPatientHistory: false,
		showInsuranceOnBookAppointment: false,
		showInsuranceOnAppointmentDetails: false,
		showNpiOnProviderDetails: false,
		enableProviderReferrerInformationPopUp: false,
		allowMHDCancellations: false,
		allowMHDReschedules: false,
		allowPSACancellations: false,
		allowPSAReschedules: false,
		reasonForVisitCharacterLimit: 8000,
		showReasonForCancellation: false,
		showReasonForCancellationPatientHistory: false,
		enableAddAttachments: false,
		enableCustomFields: false,
		enableCustomCancelReasons: false,
		enableRequestAppointment: false,
		enablePatientNotesPopUp: false,
		autoExpandPatientHeaderForVisibleNotes: false,
		enableBookAnotherWithSameDecisionSupport: false,
		enableBookAnotherWithSameDecisionSupportAndProvider: false,
		bookAnotherWithSameDecisionSupportMaxAgeInDays: 15,
		enableBookOpenDecisionSupportWorkflow: false,
		bookOpenDecisionSupportWorkflowMaxAgeInDays: 15,
		idProductInstanceConsumerCancellation: null,
		enableWaitlist: false,
	},
	system: {
		isMemberIdActive: false,
		isMemberIdRequired: false,
		memberIdFieldLabel: '',
		memberIdMaxLength: null,
		isGroupNumberActive: false,
		isGroupNumberRequired: false,
		groupNumberMaxLength: null,
	},
	instance: {
		isLoaded: false,
		isInstanceUnavailable: false,
		instanceUnavailableText: '',
		showSupportEmail: true,
		showSupportPhone: true,
		supportEmailOverride: '',
		supportPhoneOverride: '',
		logoUrl: '/resources/eh-logo--color.svg',
		idPgmStateDefault: null,
		host: '',
	},
	openWorkflowPanel: {},
	consumerProductInstances: [],
	systemCancelReasons: [],
	passwordConfig: {
		requireDigit: false,
		requiredLength: 0,
		requireNonAlphanumeric: false,
		requireUppercase: false,
		requireLowercase: false,
		historyCount: 0,
		maxLength: 0,
	},
};

function resolveConsumerProductInstances(productInstances) {
	let defaultOptionArray = productInstances.length > 1 ? [{ ...defaultOption }] : [];
	let result = defaultOptionArray.concat(
		productInstances.map((productInstance) => {
			return {
				id: productInstance.id,
				name: productInstance.name,
			};
		}),
	);
	return result;
}

function resolveSystemCancelReasons(systemCancelReasons) {
	let defaultOptionArray = systemCancelReasons.length > 1 ? [{ ...defaultOption }] : [];
	let result = defaultOptionArray.concat(
		systemCancelReasons.map((cancelReason) => {
			return {
				id: cancelReason.id,
				name: cancelReason.cancelReason,
			};
		}),
	);
	return result;
}

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case types.PROCESS_PRODUCT_INSTANCE_SUCCESS:
		case types.AUTHENTICATE_USER_SUCCESS:
		case types.VERIFY_TOKEN_SUCCESS:
			var fieldGroups = action.payload.data.config.fieldGroups;
			var patientFieldGroup = fieldGroups.find((o) => o.groupToken === patientFieldsConst.PATIENT_FIELD_GROUP);
			var patientFields = patientFieldGroup ? patientFieldGroup.fields : [];

			var patientSearchFieldGroup = fieldGroups.find(
				(o) => o.groupToken === patientFieldsConst.PATIENT_SEARCH_FIELD_GROUP,
			);
			var patientSearchFields = patientSearchFieldGroup ? patientSearchFieldGroup.fields : [];

			var providerFieldGroup = fieldGroups.find((o) => o.groupToken === providerFieldsConst.PROVIDER_FIELD_GROUP);
			var providerFields = providerFieldGroup ? providerFieldGroup.fields : [];

			var availabilitySearchFieldGroup = fieldGroups.find(
				(o) => o.groupToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_FIELD_GROUP,
			);
			var availabilitySearchFields = availabilitySearchFieldGroup ? availabilitySearchFieldGroup.fields : [];

			var appointmentFieldGroup = fieldGroups.find(
				(o) => o.groupToken === appointmentFieldsConst.APPOINTMENT_FIELD_GROUP,
			);
			var appointmentFields = appointmentFieldGroup ? appointmentFieldGroup.fields : [];

			var careOrderFieldGroup = fieldGroups.find((o) => o.groupToken === careOrderFieldsConst.CARE_ORDER_FIELD_GROUP);
			var careOrderFields = careOrderFieldGroup ? careOrderFieldGroup.fields : [];

			var careOrderPanelFieldGroup = fieldGroups.find(
				(o) => o.groupToken === careOrderPanelFieldsConst.CARE_ORDER_PANEL_FIELD_GROUP,
			);
			var careOrderPanelFields = careOrderPanelFieldGroup ? careOrderPanelFieldGroup.fields : [];

			var openWorkflowPanelFieldGroup = fieldGroups.find(
				(o) => o.groupToken === openWorkflowPanelFieldsConst.OPEN_WORKFLOW_PANEL_FIELD_GROUP,
			);
			var openWorkflowPanelFields = openWorkflowPanelFieldGroup ? openWorkflowPanelFieldGroup.fields : [];

			return {
				...state,
				availabilitySearch: {
					...state.availabilitySearch,
					...action.payload.data.config.availabilitySearch,
					searchFields: {
						appointmentStartDate: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_APPOINTMENT_START_DATE,
							),
						),
						insuranceState: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_INSURANCE_STATE,
							),
						),
						insuranceCarrier: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_INSURANCE_CARRIER,
							),
						),
						serviceCategory: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_SERVICE_CATEGORY,
							),
						),
						specialty: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_SPECIALTY,
							),
						),
						appointmentType: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_APPOINTMENT_TYPE,
							),
						),
						zipCode: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_ZIP_CODE,
							),
						),
						searchRadius: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_SEARCH_RADIUS,
							),
						),
						serviceName: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_SERVICE_NAME,
							),
						),
						providerGender: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_PROVIDER_GENDER,
							),
						),
						language: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_LANGUAGE,
							),
						),
						days: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_DAYS_OF_WEEK,
							),
						),
						timeOfDay: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_TIME_OF_DAY,
							),
						),
						payorType: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_PAYOR_TYPE,
							),
						),
						sortOrder: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_SORT_ORDER,
							),
						),
						appointmentTypeFilter: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_APPOINTMENT_TYPE_FILTER,
							),
						),
						appointmentModalityFilter: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_APPOINTMENT_MODALITY_FILTER,
							),
						),
						siteName: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_SITE_NAME,
							),
						),
						siteFilter: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_SITE_FILTER,
							),
						),
					},
				},
				availabilitySearchSupportData: {
					...state.availabilitySearchSupportData,
					...action.payload.data.config.availabilitySearchSupportData,
				},
				careOrder: {
					status: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderFields.find((o) => o.fieldToken === careOrderFieldsConst.CARE_ORDER_FIELD_STATUS),
					),
					statusReason: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderFields.find((o) => o.fieldToken === careOrderFieldsConst.CARE_ORDER_FIELD_STATUS_REASON),
					),
					careOrderType: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderFields.find((o) => o.fieldToken === careOrderFieldsConst.CARE_ORDER_FIELD_CARE_ORDER_TYPE),
					),
					//createdDate: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(careOrderFields.find(o => o.fieldToken === careOrderFieldsConst.CARE_ORDER_FIELD_CREATED_DATE)),
					orderDate: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderFields.find((o) => o.fieldToken === careOrderFieldsConst.CARE_ORDER_FIELD_ORDER_DATE),
					),
					expirationDate: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderFields.find((o) => o.fieldToken === careOrderFieldsConst.CARE_ORDER_FIELD_EXPIRATION_DATE),
					),
					minDate: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderFields.find((o) => o.fieldToken === careOrderFieldsConst.CARE_ORDER_FIELD_MIN_DATE),
					),
					maxDate: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderFields.find((o) => o.fieldToken === careOrderFieldsConst.CARE_ORDER_FIELD_MAX_DATE),
					),
					site: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderFields.find((o) => o.fieldToken === careOrderFieldsConst.CARE_ORDER_FIELD_SITE),
					),
					service: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderFields.find((o) => o.fieldToken === careOrderFieldsConst.CARE_ORDER_FIELD_SERVICE),
					),
					referringSite: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderFields.find((o) => o.fieldToken === careOrderFieldsConst.CARE_ORDER_FIELD_REFERRING_SITE),
					),
					referringService: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderFields.find((o) => o.fieldToken === careOrderFieldsConst.CARE_ORDER_FIELD_REFERRING_SERVICE),
					),
					specialty: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderFields.find((o) => o.fieldToken === careOrderFieldsConst.CARE_ORDER_FIELD_SPECIALTY),
					),
					subgroup: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderFields.find((o) => o.fieldToken === careOrderFieldsConst.CARE_ORDER_FIELD_SUBGROUP),
					),
					appointmentType: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderFields.find((o) => o.fieldToken === careOrderFieldsConst.CARE_ORDER_FIELD_APPOINTMENT_TYPE),
					),
					reasonForVisit: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderFields.find((o) => o.fieldToken === careOrderFieldsConst.CARE_ORDER_FIELD_REASON_FOR_VISIT),
					),
					bookedAppointment: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderFields.find((o) => o.fieldToken === careOrderFieldsConst.CARE_ORDER_FIELD_BOOKED_APPOINTMENT),
					),
					externalOrderNumber: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderFields.find((o) => o.fieldToken === careOrderFieldsConst.CARE_ORDER_FIELD_EXTERNAL_ORDER_NUMBER),
					),
					//lastModifiedAt: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(careOrderFields.find(o => o.fieldToken === careOrderFieldsConst.CARE_ORDER_FIELD_LAST_MODIFIED_AT)),
					//lastEmailDate: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(careOrderFields.find(o => o.fieldToken === careOrderFieldsConst.CARE_ORDER_FIELD_LAST_EMAIL_DATE)),
					productInstanceConsumer: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderFields.find(
							(o) => o.fieldToken === careOrderFieldsConst.CARE_ORDER_FIELD_PRODUCT_INSTANCE_CONSUMER,
						),
					),
					//lockoutEndAt: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(careOrderFields.find(o => o.fieldToken === careOrderFieldsConst.CARE_ORDER_FIELD_LOCKOUT_END_AT)),
					customFields: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderFields.find((o) => o.fieldToken === careOrderFieldsConst.CARE_ORDER_FIELD_CUSTOM_FIELDS),
					),
					notes: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderFields.find((o) => o.fieldToken === careOrderFieldsConst.CARE_ORDER_FIELD_NOTES),
					),
				},
				careOrderPanel: {
					status: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderPanelFields.find((o) => o.fieldToken === careOrderPanelFieldsConst.CARE_ORDER_PANEL_FIELD_STATUS),
					),
					statusReason: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderPanelFields.find(
							(o) => o.fieldToken === careOrderPanelFieldsConst.CARE_ORDER_PANEL_FIELD_STATUS_REASON,
						),
					),
					careOrderType: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderPanelFields.find(
							(o) => o.fieldToken === careOrderPanelFieldsConst.CARE_ORDER_PANEL_FIELD_CARE_ORDER_TYPE,
						),
					),
					orderDate: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderPanelFields.find(
							(o) => o.fieldToken === careOrderPanelFieldsConst.CARE_ORDER_PANEL_FIELD_ORDER_DATE,
						),
					),
					expirationDate: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderPanelFields.find(
							(o) => o.fieldToken === careOrderPanelFieldsConst.CARE_ORDER_PANEL_FIELD_EXPIRATION_DATE,
						),
					),
					minDate: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderPanelFields.find(
							(o) => o.fieldToken === careOrderPanelFieldsConst.CARE_ORDER_PANEL_FIELD_MIN_DATE,
						),
					),
					maxDate: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderPanelFields.find(
							(o) => o.fieldToken === careOrderPanelFieldsConst.CARE_ORDER_PANEL_FIELD_MAX_DATE,
						),
					),
					site: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderPanelFields.find((o) => o.fieldToken === careOrderPanelFieldsConst.CARE_ORDER_PANEL_FIELD_SITE),
					),
					service: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderPanelFields.find((o) => o.fieldToken === careOrderPanelFieldsConst.CARE_ORDER_PANEL_FIELD_SERVICE),
					),
					referringSite: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderPanelFields.find(
							(o) => o.fieldToken === careOrderPanelFieldsConst.CARE_ORDER_PANEL_FIELD_REFERRING_SITE,
						),
					),
					referringService: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderPanelFields.find(
							(o) => o.fieldToken === careOrderPanelFieldsConst.CARE_ORDER_PANEL_FIELD_REFERRING_SERVICE,
						),
					),
					specialty: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderPanelFields.find(
							(o) => o.fieldToken === careOrderPanelFieldsConst.CARE_ORDER_PANEL_FIELD_SPECIALTY,
						),
					),
					subgroup: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderPanelFields.find(
							(o) => o.fieldToken === careOrderPanelFieldsConst.CARE_ORDER_PANEL_FIELD_SUBGROUP,
						),
					),
					appointmentType: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderPanelFields.find(
							(o) => o.fieldToken === careOrderPanelFieldsConst.CARE_ORDER_PANEL_FIELD_APPOINTMENT_TYPE,
						),
					),
					reasonForVisit: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderPanelFields.find(
							(o) => o.fieldToken === careOrderPanelFieldsConst.CARE_ORDER_PANEL_FIELD_REASON_FOR_VISIT,
						),
					),
					bookedAppointment: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderPanelFields.find(
							(o) => o.fieldToken === careOrderPanelFieldsConst.CARE_ORDER_PANEL_FIELD_BOOKED_APPOINTMENT,
						),
					),
					externalOrderNumber: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderPanelFields.find(
							(o) => o.fieldToken === careOrderPanelFieldsConst.CARE_ORDER_PANEL_FIELD_EXTERNAL_ORDER_NUMBER,
						),
					),
					productInstanceConsumer: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderPanelFields.find(
							(o) => o.fieldToken === careOrderPanelFieldsConst.CARE_ORDER_PANEL_FIELD_PRODUCT_INSTANCE_CONSUMER,
						),
					),
					customFields: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderPanelFields.find(
							(o) => o.fieldToken === careOrderPanelFieldsConst.CARE_ORDER_PANEL_FIELD_CUSTOM_FIELDS,
						),
					),
					notes: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						careOrderPanelFields.find((o) => o.fieldToken === careOrderPanelFieldsConst.CARE_ORDER_PANEL_FIELD_NOTES),
					),
				},
				openWorkflowPanel: {
					completionDate: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						openWorkflowPanelFields.find((o) => o.fieldToken === openWorkflowPanelFieldsConst.OPEN_WORKFLOW_PANEL_FIELD_COMPLETION_DATE),
					),
					createdBy: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						openWorkflowPanelFields.find(
							(o) => o.fieldToken === openWorkflowPanelFieldsConst.OPEN_WORKFLOW_PANEL_FIELD_CREATED_BY,
						),
					),
					productInstance: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						openWorkflowPanelFields.find(
							(o) => o.fieldToken === openWorkflowPanelFieldsConst.OPEN_WORKFLOW_PANEL_FIELD_PRODUCT_INSTANCE,
						),
					),
					appointmentDate: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						openWorkflowPanelFields.find(
							(o) => o.fieldToken === openWorkflowPanelFieldsConst.OPEN_WORKFLOW_PANEL_FIELD_APPOINTMENT_DATE,
						),
					),
					questionsAndAnswers: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						openWorkflowPanelFields.find(
							(o) => o.fieldToken === openWorkflowPanelFieldsConst.OPEN_WORKFLOW_PANEL_FIELD_QUESTIONS_AND_ANSWERS,
						),
					),
				},
				decisionSupport: {
					...state.decisionSupport,
					...action.payload.data.config.decisionSupport,
				},
				externalAuth: {
					...state.externalAuth,
					...(action.payload.data.config.externalAuth
						? action.payload.data.config.externalAuth
						: initialState.externalAuth),
				},
				patient: {
					...state.patient,
					allowUnknownGender: action.payload.data.config.allowUnknownGender,
					unknownGenderOptionText:
						action.payload.data.config.unknownGenderOptionText &&
							action.payload.data.config.unknownGenderOptionText !== null
							? action.payload.data.config.unknownGenderOptionText
							: 'Unspecified',
					isAddEnabled: action.payload.data.config.patientManagement.enablePatientAdd,
					isEditEnabled: action.payload.data.config.patientManagement.enablePatientEdit,
					enablePatientSearchByDemographics:
						action.payload.data.config.patientManagement.enablePatientSearchByDemographics,
					enablePatientSearchByIdentity: action.payload.data.config.patientManagement.enablePatientSearchByIdentity,
					enablePatientSearchByMemberId: action.payload.data.config.patientManagement.enablePatientSearchByMemberId,
					enablePatientSearchByPhone: action.payload.data.config.patientManagement.enablePatientSearchByPhone,
					patientIdentityFieldLabelOverride:
						action.payload.data.config.patientManagement.patientIdentityFieldLabelOverride,
					excludeWithoutMemberIdFromPatientSearch:
						action.payload.data.config.patientManagement.excludePatientsWithoutMemberIdFromPatientSearch,
					usePatientProxy: action.payload.data.config.patientManagement.usePatientProxy,
					//patient search
					firstNameRequired: action.payload.data.config.firstNameRequired,
					lastNameRequired: action.payload.data.config.lastNameRequired,
					dateOfBirthRequired: action.payload.data.config.dateOfBirthRequired,
					//end patient search
					isPatientHistoryEnabled: action.payload.data.config.patientManagement.enablePatientHistory,
					showPatientHistoryInPatientInfoPanel: action.payload.data.config.patientManagement.showPatientHistoryInPatientInfoPanel,
					showAddressSearch: action.payload.data.config.patientManagement.showAddressSearch,
					isPatientCareOrderHistoryEnabled: action.payload.data.config.patientManagement.enablePatientCareOrderHistory,
					includeInactiveCareOrders: action.payload.data.config.patientManagement.includeInactiveCareOrders,
					isDecisionSupportWorkflowHistoryEnabled: action.payload.data.config.patientManagement.enableDecisionSupportWorkflowHistory,
					allowExternalPatientDetailsUrl: action.payload.data.config.patientManagement.allowExternalPatientDetailsUrl,
					gender: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_GENDER),
					),
					memberId: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_MEMBERID),
					),
					groupNumber: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_GROUPNUMBER),
					),
					firstName: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_FIRSTNAME),
					),
					middleName: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_MIDDLENAME),
					),
					lastName: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_LASTNAME),
					),
					addressLine1: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_ADDRESSLINE1),
					),
					addressLine2: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_ADDRESSLINE2),
					),
					city: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_CITY),
					),
					state: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_STATE),
					),
					zipCode: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_ZIPCODE),
					),
					dateOfBirth: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_DATEOFBIRTH),
					),
					email: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_EMAIL),
					),
					alternatePhone: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_ALTERNATEPHONE),
					),
					homePhone: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_HOMEPHONE),
					),
					mobilePhone: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_MOBILEPHONE),
					),
					parentOrGuardianName: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_PARENT_OR_GUARDIAN_NAME),
					),
					notes: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_NOTES),
					),
				},
				patientSearch: {
					firstName: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientSearchFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_SEARCH_FIELD_FIRSTNAME),
					),
					lastName: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientSearchFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_SEARCH_FIELD_LASTNAME),
					),
					dateOfBirth: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientSearchFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_SEARCH_FIELD_DATEOFBIRTH),
					),
				},
				provider: {
					fax: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						providerFields.find((o) => o.fieldToken === providerFieldsConst.PROVIDER_FIELD_FAX),
					),
					phone: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						providerFields.find((o) => o.fieldToken === providerFieldsConst.PROVIDER_FIELD_PHONE),
					),
				},
				appointmentManagement: {
					...state.appointmentManagement,
					enableEditReasonForVisit: action.payload.data.config.appointmentManagement.enableEditReasonForVisit,
					enableEditProviderResponse: action.payload.data.config.appointmentManagement.enableEditProviderResponse,
					allowExternalAppointmentDetailsUrl: action.payload.data.config.appointmentManagement.allowExternalAppointmentDetailsUrl,
				},
				orderManagement: action.payload.data.config.orderManagement
					? {
						...state.orderManagement,
						usesSystemDateRange: action.payload.data.config.orderManagement.usesSystemDateRange,
						maxAppointmentsPerVisit: action.payload.data.config.orderManagement.maxAppointmentsPerVisit,
						maxVisitsPerOrder: action.payload.data.config.orderManagement.maxVisitsPerOrder,
						usesExternalOrders: action.payload.data.config.orderManagement.usesExternalOrders,
						closeCareOrderEnabled: action.payload.data.config.orderManagement.closeCareOrderEnabled,
						patientRequestedOrderEnabled: action.payload.data.config.orderManagement.patientRequestedOrderEnabled,
						idProductInstanceConsumerCareOrder: action.payload.data.config.orderManagement.idProductInstanceConsumerCareOrder,
						patientRequestedOrderExpirationLeadDays: action.payload.data.config.orderManagement.patientRequestedOrderExpirationLeadDays,
					}
					: state.orderManagement,
				orderScheduling: {
					...state.orderScheduling,
					enableOrderDetailsPanel: action.payload.data.config.orderScheduling.enableOrderDetailsPanel,
					autoExpandOrderDetailsPanel: action.payload.data.config.orderScheduling.autoExpandOrderDetailsPanel,
					searchType: action.payload.data.config.orderScheduling.searchType
				},
				notification: {
					...state.notification,
					languageId: action.payload.data.config.notification.language
						? action.payload.data.config.notification.language.idLanguage
						: 0,
					emailConfirmationEnabled: action.payload.data.config.notification.enablePatientConfirmationEmail,
					emailCancellationEnabled: action.payload.data.config.notification.enablePatientCancellationEmail,
					emailReminderEnabled: action.payload.data.config.notification.enablePatientEmailReminder,
					minEmailReminderLeadTime: action.payload.data.config.notification.minEmailReminderLeadTime,
					minTextReminderLeadTime: action.payload.data.config.notification.minTextReminderLeadTime,
					minVoiceReminderLeadTime: action.payload.data.config.notification.minVoiceReminderLeadTime,
					patientConfirmationEmailDisclaimer:
						action.payload.data.config.notification.patientConfirmationEmailDisclaimer,
					patientCancellationEmailDisclaimer:
						action.payload.data.config.notification.patientCancellationEmailDisclaimer,
					patientReminderEmailDisclaimer: action.payload.data.config.notification.patientReminderEmailDisclaimer,
					patientReminderVoiceDisclaimer: action.payload.data.config.notification.patientReminderVoiceDisclaimer,
					patientReminderSmsDisclaimer: action.payload.data.config.notification.patientReminderSmsDisclaimer,
					showPatientConfirmationEmailOptIn: action.payload.data.config.notification.showPatientConfirmationEmailOptIn,
					showPatientCancellationEmailOptIn: action.payload.data.config.notification.showPatientCancellationEmailOptIn,
					voiceReminderEnabled: action.payload.data.config.notification.enablePatientVoiceReminder,
					voiceReminderOtherPhoneAllowed: action.payload.data.config.notification.allowVoiceReminderOtherPhone,
					textReminderEnabled: action.payload.data.config.notification.enablePatientSmsReminder,
					textReminderOtherPhoneAllowed: action.payload.data.config.notification.allowTextReminderOtherPhone,
					enablePatientCareOrderEmail: action.payload.data.config.notification.enablePatientCareOrderEmail,
					enablePatientCareOrderSms: action.payload.data.config.notification.enablePatientCareOrderSms,
					allowPatientCareOrderOtherEmail: action.payload.data.config.notification.allowPatientCareOrderOtherEmail,
					allowPatientCareOrderOtherPhone: action.payload.data.config.notification.allowPatientCareOrderOtherPhone,
					patientCareOrderEmailDisclaimer: action.payload.data.config.notification.patientCareOrderEmailDisclaimer,
					patientCareOrderSmsDisclaimer: action.payload.data.config.notification.patientCareOrderSmsDisclaimer,
				},
				scheduling: {
					...state.scheduling,
					maxAttachments: action.payload.data.config.system.appointmentMaxAttachments,
					showReasonForCancellation: action.payload.data.config.scheduling.showReasonForCancellation,
					showReasonForCancellationPatientHistory:
						action.payload.data.config.scheduling.showReasonForCancellationPatientHistory,
					enableAddAttachments: action.payload.data.config.scheduling.enableAddAttachments,
					enableCustomFields: action.payload.data.config.scheduling.enableCustomFields,
					customFieldsConfigInstructions: action.payload.data.config.scheduling.customFieldsConfigInstructions,
					enableCustomCancelReasons: action.payload.data.config.scheduling.enableCustomCancelReasons,
					enableRequestAppointment: action.payload.data.config.scheduling.enableRequestAppointment,
					reasonForVisit: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						appointmentFields.find((o) => o.fieldToken === appointmentFieldsConst.APPOINTMENT_REASON_FOR_VISIT),
					),
					requestEarlierAppointmentFieldConfig: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						appointmentFields.find(
							(o) => o.fieldToken === appointmentFieldsConst.APPOINTMENT_REQUEST_EARLIER_APPOINTMENT,
						),
					),
					showProviderResponseApptDetails: action.payload.data.config.scheduling.showProviderResponseApptDetails,
					showReasonForVisitApptDetails: action.payload.data.config.scheduling.showReasonForVisitApptDetails,
					showProviderResponsePatientHistory: action.payload.data.config.scheduling.showProviderResponsePatientHistory,
					showReasonForVisitPatientHistory: action.payload.data.config.scheduling.showReasonForVisitPatientHistory,
					showInsuranceOnBookAppointment: action.payload.data.config.scheduling.showInsuranceOnBookAppointment,
					showInsuranceOnAppointmentDetails: action.payload.data.config.scheduling.showInsuranceOnAppointmentDetails,
					showNpiOnProviderDetails: action.payload.data.config.scheduling.showNpiOnProviderDetails,
					enableProviderReferrerInformationPopUp:
						action.payload.data.config.scheduling.enableProviderReferrerInformationPopUp,
					referringProvider: {
						...state.scheduling.referringProvider,
						useReferringProviders: action.payload.data.config.scheduling.useReferringProviders,
						allowExternalReferringProviders: action.payload.data.config.scheduling.allowExternalReferringProviders,
						allowInternalReferringProviders: action.payload.data.config.scheduling.allowInternalReferringProviders,
						allowOrganizationReferringProviders:
							action.payload.data.config.scheduling.allowOrganizationReferringProviders,
						managedReferringProviderFieldConfig: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							appointmentFields.find(
								(o) => o.fieldToken === appointmentFieldsConst.APPOINTMENT_MANAGED_REFERRING_PROVIDER,
							),
						),
						unmanagedReferringProviderFieldConfig: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							appointmentFields.find(
								(o) => o.fieldToken === appointmentFieldsConst.APPOINTMENT_UNMANAGED_REFERRING_PROVIDER,
							),
						),
						organizationReferringProviderFieldConfig: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							appointmentFields.find(
								(o) => o.fieldToken === appointmentFieldsConst.APPOINTMENT_ORGANIZATION_REFERRING_PROVIDER,
							),
						),
						enableInternalReferringProviderServerTypeAhead:
							action.payload.data.config.scheduling.enableInternalReferringProviderServerTypeAhead,
						internalReferringProviderServerTypeAheadMinimumCharacters:
							action.payload.data.config.scheduling.internalReferringProviderServerTypeAheadMinimumCharacters,
					},
					allowMHDCancellations: action.payload.data.config.scheduling.allowAppointmentCancellations,
					allowMHDReschedules: action.payload.data.config.scheduling.allowAppointmentReschedules,
					allowPSACancellations: action.payload.data.config.scheduling.allowPSACancellations,
					allowPSAReschedules: action.payload.data.config.scheduling.allowPSAReschedules,
					allowReschedulePSAWithSameProvider: action.payload.data.config.scheduling.allowReschedulePSAWithSameProvider,
					reasonForVisitCharacterLimit: action.payload.data.config.scheduling.reasonForVisitCharacterLimit,
					reservationDurationInMinutes: action.payload.data.config.scheduling.reservationDurationInMinutes,
					enablePatientNotesPopUp: action.payload.data.config.scheduling.enablePatientNotesPopUp,
					autoExpandPatientHeaderForVisibleNotes:
						action.payload.data.config.scheduling.autoExpandPatientHeaderForVisibleNotes,
					useDecisionSupportWithCareOrders: action.payload.data.config.scheduling.useDecisionSupportWithCareOrders,
					enableBookAnotherWithSameDecisionSupport: action.payload.data.config.scheduling.enableBookAnotherWithSameDecisionSupport,
					enableBookAnotherWithSameDecisionSupportAndProvider: action.payload.data.config.scheduling.enableBookAnotherWithSameDecisionSupportAndProvider,
					bookAnotherWithSameDecisionSupportMaxAgeInDays: action.payload.data.config.scheduling.bookAnotherWithSameDecisionSupportMaxAgeInDays,
					enableBookOpenDecisionSupportWorkflow: action.payload.data.config.scheduling.enableBookOpenDecisionSupportWorkflow,
					bookOpenDecisionSupportWorkflowMaxAgeInDays: action.payload.data.config.scheduling.bookOpenDecisionSupportWorkflowMaxAgeInDays,
					idProductInstanceConsumerCancellation: action.payload.data.config.scheduling.idProductInstanceConsumerCancellation,
					enableWaitlist: action.payload.data.config.scheduling.enableWaitlist
				},
				system: {
					...state.system,
					isMemberIdActive: action.payload.data.config.system.isMemberIdActive,
					isMemberIdRequired: action.payload.data.config.system.isMemberIdRequired,
					memberIdFieldLabel: action.payload.data.config.system.memberIdFieldLabel,
					memberIdMaxLength: action.payload.data.config.system.memberIdMaxLength,
					isGroupNumberActive: action.payload.data.config.system.isGroupNumberActive,
					isGroupNumberRequired: action.payload.data.config.system.isGroupNumberRequired,
					groupNumberMaxLength: action.payload.data.config.system.groupNumberMaxLength,
				},
				instance: {
					...state.instance,
					isInstanceUnavailable: action.payload.data.config.isInstanceUnavailable,
					instanceUnavailableText: action.payload.data.config.instanceUnavailableText,
					logoUrl: action.payload.data.config.logoUrl,
					showSupportEmail: action.payload.data.config.showSupportEmail,
					showSupportPhone: action.payload.data.config.showSupportPhone,
					supportEmailOverride: action.payload.data.config.supportEmailOverride,
					supportPhoneOverride: action.payload.data.config.supportPhoneOverride,
					isLoaded: true,
					idPgmStateDefault: action.payload.data.config.idPgmStateDefault,
					host: action.payload.data.config.host,
				},
				consumerProductInstances: resolveConsumerProductInstances(action.payload.data.config.consumerProductInstances),
				systemCancelReasons: action.payload.data.config.cancelReasons
					? resolveSystemCancelReasons(action.payload.data.config.cancelReasons)
					: [],
				passwordConfig: {
					requireDigit: action.payload.data.config.passwordConfig.requireDigit,
					requiredLength: action.payload.data.config.passwordConfig.requiredLength,
					requireNonAlphanumeric: action.payload.data.config.passwordConfig.requireNonAlphanumeric,
					requireUppercase: action.payload.data.config.passwordConfig.requireUppercase,
					requireLowercase: action.payload.data.config.passwordConfig.requireLowercase,
					passwordHistoryCount: action.payload.data.config.passwordConfig.historyCount,
					maxLength: action.payload.data.config.passwordConfig.maxLength,
				},
			};
		default:
			return state;
	}
}
